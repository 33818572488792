import React, { Component, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FilemanagerService from '../../../../services/FilemanagerService';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core/';

import IconFolder from '@material-ui/icons/Folder';
import IconAdd from '@material-ui/icons/Add';
import IconCancelPresentation from '@material-ui/icons/CancelPresentation';
import IconInsertDriveFile from '@material-ui/icons/InsertDriveFile';
import IconToc from '@material-ui/icons/Toc';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconCallMerge from '@material-ui/icons/CallMerge';
import IconCropPortrait from '@material-ui/icons/CropPortrait';
import IconAddPhoto from '@material-ui/icons/AddPhotoAlternate';
import APIService from '../../../../services/APIService';
import strings from '../../../../lang/l10n';

const Dialogs = {
  extraPageMethod: 1,
  addExtraPageCreateNew: 2,
}

const OutputTypes = {
  pdf: 'PDF',
  xlsx: 'XLSX',
}

const Templates = {
  proposal: 'Proposal',
  offer: 'Offer',
}

const styles = (theme) => ({
  toc: {
    backgroundColor: '#f5f5f5',
    width: '100%',
  },
  page: {
    backgroundColor: '#e0e0e0',
    width: '100%',
  },
  pageItem: {
    backgroundColor: '#fff',
    marginBottom: '0.3rem',
    marginTop: '0.3rem',
    width: '100%',
    overflow: 'hidden',
  },
  black: {
    color: '#000',
  },
  btnOutline: {
    borderRadius: 0,
    border: '2px solid black',
    color: '#000',
    padding: '0.5em 1em',
    fontWeight: 500,
  },
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getFilename = (url) => {
  return url.substring(url.lastIndexOf('/')+1)
}

const PageItems = (props) => {
  const { page, pageNum, classes } = props;
  return <Droppable droppableId={`droppable${page.id}`} type={`${pageNum}`}>
    {(provided, snapshot) => (
      <List className={classes.toc} ref={provided.innerRef}>
        {page.pageItems.map((pageItem, index) => {
          //console.log(page, pageItem);

          return <Draggable
            key={`${pageNum}${index}`}
            draggableId={`${pageNum}${index}`}
            index={index}
          >
            {(provided, snapshot) => (
              <ListItem className={classes.pageItem}
                itemSize={50}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {page.icons && page.icons[index] && (<ListItemIcon>
                  <div style={{
                    backgroundColor: '#fff',
                    backgroundImage: "url('" + page.icons[index] + "')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    width: '50px',
                    height: '50px',
                    border: '3px solid #fff',
                    marginRight: '15px'
                  }}>
                  </div>
                </ListItemIcon>)}
                <ListItemText primary={pageItem} />
                <ListItemSecondaryAction>
                  {/* <IconButton className={classes.black}>
                    <IconClear />
                  </IconButton> */}
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </Draggable>
        })}
        {provided.placeholder}
      </List>
    )}
  </Droppable>
};

const AddExtraPageCreateNewDialog = (props) => {
  const { handleClose, open, addPage } = props;
  const [values, setValues] = useState(0);

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const selectFile = () => {
    FilemanagerService.select()
      .then((url) => {
        setValues({...values, "url": url});
      });
  }

  return <Dialog open={open} onClose={handleClose} fullWidth>
    <DialogTitle>{strings.finalizeSettings.dialog.addNewPage}</DialogTitle>
    <DialogContent>
      <InputLabel>{strings.finalizeSettings.dialog.selectImage}</InputLabel>
        <Input
          name="image"
          value={values.url ? getFilename(values.url) : ''}
          type="text"
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={selectFile}>
                <IconAddPhoto/>
              </IconButton>
            </InputAdornment>
          }
        />
      <TextField onChange={handleInputChange} name="title" margin="dense" label={strings.finalizeSettings.dialog.title} type="text" fullWidth/>
      <TextField onChange={handleInputChange} name="line1" margin="dense" label={`${strings.finalizeSettings.dialog.personalizationLine} 1`} type="text" fullWidth/>
      <TextField onChange={handleInputChange} name="line2" margin="dense" label={`${strings.finalizeSettings.dialog.personalizationLine} 2`} type="text" fullWidth/>
      <TextField onChange={handleInputChange} name="line3" margin="dense" label={`${strings.finalizeSettings.dialog.personalizationLine} 3`} type="text" fullWidth/>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => {addPage(values)}} color="primary">{strings.finalizeSettings.dialog.addThisPage}</Button>
      <Button onClick={handleClose} color="primary">{strings.finalizeSettings.dialog.cancel}</Button>
    </DialogActions>
  </Dialog>
}

class FinalizeTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialog: false,
      customPages: [],
    };
  }

  componentDidMount = () => {
    if(this.props.offer.tableOfContents.length > 0) {
      this.setState({ customPages: this.props.offer.tableOfContents.filter((v) => v.type === 'custom') },
      () => {
        this.props.updateTableOfContents(this.getPages(this.props.offer.offerTemplate, this.props.offer.outputType));
      });
    } else {
      this.props.updateTableOfContents(this.getPages(this.props.offer.offerTemplate, this.props.offer.outputType));
    }

    this.props.updateProductUIDs();
  }

  showDialog = (dialog) => {
    this.setState({ dialog });
  }

  closeDialog = () => {
    this.setState({ dialog: false });
  }

  changeOutputType = (event) => {
    this.props.handleOfferTextfieldChange("outputType", event.target.value);
    this.props.updateTableOfContents(this.getPages(this.props.offer.offerTemplate, event.target.value));
  }

  changeTemplate = (event) => {
    this.props.handleOfferTextfieldChange("offerTemplate", event.target.value);
    this.props.updateTableOfContents(this.getPages(event.target.value, this.props.offer.outputType));
  }

  onDragEnd = (result) => {
    if (!result.destination) { return; }

    if (result.type === "PAGES") {
      const pages = reorder(this.props.offer.tableOfContents, result.source.index, result.destination.index);
      this.props.updateTableOfContents(pages);
    } else {
      const pageItems = reorder(this.props.offer.tableOfContents[parseInt(result.type, 10)].pageItems, result.source.index, result.destination.index);

      const pages = JSON.parse(JSON.stringify(this.props.offer.tableOfContents));
      pages[result.type].pageItems = pageItems;

      this.props.updateTableOfContents(pages);
    }
  }

  reorderPages = (pages) => {
    const { offer } = this.props;

    let keyedPages = pages.reduce((acc, page) => {
      acc[page.id] = page;
      return acc;
    }, {});

    let sortedPages = offer.pagesOrder.map((pageId) => {
      let page = null;

      if (keyedPages[pageId]) {
        page = keyedPages[pageId];
        delete keyedPages[pageId];
      }

      return page;
    }).filter((o) => o !== null);

    sortedPages = sortedPages.concat(Object.values(keyedPages));
    return sortedPages;
  };

  getPages = (template, output) => {
    const { offer } = this.props;

    let pages = [];

    if (output === OutputTypes.pdf) {
      const useProposal = template === Templates.proposal;
      const productData = useProposal ? offer.products : this.getOfferPages();

      pages = Array.from(productData, (v, k) => k).map(k => ({
        id: `products-${k + 1}`,
        text: useProposal
          ? [`${strings.finalizeSettings.product} (${productData[k].ItemCode})`]
          : strings.finalizeSettings.productsPage,
        type: 'product',
        icons: useProposal
          ? [this.props.getProductImageSrc(productData[k])]
          : productData[k].map((product) => this.props.getProductImageSrc(product)),
        pageItems: useProposal
          ? [productData[k].ItemFamily + " " + productData[k].ItemName.LocaleString[this.props.offer.settingsLanguage] + ` (${productData[k].ItemCode})`]
          : productData[k].map((product) => product.ItemFamily + " " + product.ItemName.LocaleString[this.props.offer.settingsLanguage] + ` (${product.ItemCode})`),
        data: useProposal
          ? k
          : productData[k].map((product, i) => product.uid),
      }));

      this.state.customPages.forEach((cp, i) => {
        pages.push({ id: `custom-${i}`, text: `${strings.finalizeSettings.customPage} ${i+1}`,  type: 'custom', pageItems: cp.title ? [cp.title] : [cp.pageItems[0]], url: cp.url, data: cp.data || cp });
      });

      pages.unshift({ id: 'toc-0', text: strings.finalizeSettings.tableOfContents, type: 'tableofcontents', pageItems: [strings.finalizeSettings.tableOfContents] });
      pages.unshift({ id: 'cover-0', text: strings.finalizeSettings.cover, type: 'cover', pageItems: [strings.finalizeSettings.cover] });

      if (offer.includeTotalPrice) {
        pages.push({ id: 'total-0', text: strings.finalizeSettings.priceOverview, type: 'overview', pageItems: [strings.finalizeSettings.priceOverview]});
      }
    } else if (output === OutputTypes.xlsx) {
      pages = [{
        id: `products-1`,
        text: strings.finalizeSettings.productsPage,
        type: 'product',
        icons: offer.products.map((product) => this.props.getProductImageSrc(product)),
        pageItems: offer.products.map((product) => product.ItemFamily + product.ItemName.LocaleString[this.props.offer.settingsLanguage] + ` (${product.ItemCode})`),
        data: offer.products.map((product, i) => product.uid),
      }];
    }

    pages = this.reorderPages(pages);

    return pages;
  }

  removePage = (customPage) => {
    this.setState({
      customPages: this.state.customPages.filter((page) => page.id !== customPage.id)
    }, () => { this.props.updateTableOfContents(this.getPages(this.props.offer.offerTemplate, this.props.offer.outputType)) });
  }

  getOfferPages = () => {
    const { offer } = this.props;

    const result = offer.products.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 3);

      if (!resultArray[chunkIndex]) { resultArray[chunkIndex] = []; }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    return result;
  }

  addCustomPage = (page) => {
    this.closeDialog();
    this.setState({
      customPages: [...this.state.customPages, page]
    }, () => { this.props.updateTableOfContents(this.getPages(this.props.offer.offerTemplate, this.props.offer.outputType)) });
  }

  addExtraPage = () => {
    FilemanagerService.select()
      .then((url) => {
        this.addCustomPage({ "url": url, "title": getFilename(url) });
      });
  }

  download = (e) => {
    const triggerOverlay = true;
    this.props.saveOffer(triggerOverlay, (error) => {

      if (error) { return this.props.hideLoadingOverlay(); /* TODO: Log? Something? */ }

      return APIService.downloadOffer(this.props.offer.id).then(async (response) => {
        if (response && response.content) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = 'data:application/octet-stream;base64,' + response.content
          a.target = "_blank";
          a.download = response.filename;
          a.click();
          this.props.hideLoadingOverlay();
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        } else { this.props.hideLoadingOverlay(); /* TODO: Log? Something? */ }
      });
    });
  }

  render() {
    const { classes } = this.props;
    return <div>
      <Grid container spacing={10}>
        <Grid item xs={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IconCropPortrait fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {strings.finalizeSettings.template}
            </Typography>
            </Grid>
          </Grid>

          <Select fullWidth defaultValue={this.props.offer.offerTemplate} onChange={this.changeTemplate} name="template">
            <MenuItem value={Templates.proposal}>{strings.finalizeSettings.proposal}</MenuItem>
            <MenuItem value={Templates.offer}>{strings.finalizeSettings.offer}</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={4} style={{ borderRight: '0.1em solid LightGrey', borderLeft: '0.1em solid LightGrey' }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IconCallMerge fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6">
              {strings.finalizeSettings.outputType}
            </Typography>
            </Grid>
          </Grid>

          <Select fullWidth defaultValue={this.props.offer.outputType} onChange={this.changeOutputType} name="outputType">
            <MenuItem value={OutputTypes.pdf}>{strings.finalizeSettings.pdf}</MenuItem>
            <MenuItem value={OutputTypes.xlsx}>{strings.finalizeSettings.excel}</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IconCheckCircle fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {strings.finalizeSettings.downloadDocument}
            </Typography>
            </Grid>
          </Grid>
          <Button fullWidth variant="outlined" className={classes.btnOutline} onClick={this.download}>{strings.finalizeSettings.download}</Button>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 40, marginBottom: 40 }} />
        <div>
          <Grid container justify="space-between" >
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <IconToc fontSize="large" />
                </Grid>
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    {strings.finalizeSettings.tableOfContent}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => { this.showDialog(Dialogs.extraPageMethod) }} className={classes.btnOutline}>{strings.finalizeSettings.addPage}</Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DragDropContext
              onDragEnd={this.onDragEnd}
              onDragUpdate={this.onDragUpdate}
            >
              <Droppable droppableId="droppable" type="PAGES">
                {(provided, snapshot) => (
                  <List className={classes.toc} ref={provided.innerRef}>
                    {this.props.offer.tableOfContents.map((page, index) => (
                      <Draggable
                        key={page.id}
                        draggableId={page.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ListItem className={classes.page} >
                              <ListItemIcon className={classes.black}>
                                <IconInsertDriveFile fontSize="large" />
                              </ListItemIcon>
                              <ListItemText primary={page.text} style={{ width: '100%' }} />
                              <ListItemSecondaryAction>
                                { page.type === 'custom' && <IconButton className={classes.black} onClick={() => this.removePage(page)}>
                                  <IconCancelPresentation />
                                </IconButton> }
                              </ListItemSecondaryAction>
                            </ListItem>
                            <PageItems pageNum={index} page={page} classes={classes} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </div>

        <Dialog onClose={this.closeDialog} open={this.state.dialog === Dialogs.extraPageMethod} style={{ zIndex: 99999 }}>
          <DialogTitle id="extraPagesDialog">{strings.finalizeSettings.extraPageDialog.title}</DialogTitle>
          <List>
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <IconFolder />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={strings.finalizeSettings.extraPageDialog.addFromExtraPages} onClick={this.addExtraPage}/>
            </ListItem>

            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <IconAdd />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={strings.finalizeSettings.extraPageDialog.createNewPage} onClick={() => { this.showDialog(Dialogs.addExtraPageCreateNew) }} />
            </ListItem>
          </List>
        </Dialog>

        <AddExtraPageCreateNewDialog addPage={this.addCustomPage} handleClose={this.closeDialog} open={this.state.dialog === Dialogs.addExtraPageCreateNew} />
    </div>
  }
}

export default withStyles(styles)(FinalizeTab)
