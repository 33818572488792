import React, { Component } from 'react';
import Header from '../../partials/Header/Header.js';
import './ExtraPages.scss';
import {
  Container,
  Box,
  withStyles,
} from '@material-ui/core/';

import '../../../services/FilemanagerService';
import FilemanagerService from '../../../services/FilemanagerService';

const styles = {
  filemanager: {
    width: '100%',
    minHeight: '600px',
    border: 'none'
  }
};

class ExtraPages extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return <Container id="mainview" className="new">
      <Header />
      <Box className="content">
        <iframe title="Filemanager" src={FilemanagerService.getUrl()} className={classes.filemanager}></iframe>
      </Box>
    </Container>;
  }
}

export default withStyles(styles)(ExtraPages);