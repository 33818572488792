import LocalStorageService from './LocalStorageService.js';

export async function PostDataUnauthenticated(endpoint, data) {
  const BaseURL = process.env.REACT_APP_API_HOST;

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': "application/json"
      }
    };
    
    payload.headers['Content-Type'] = "application/json";

    
    fetch(BaseURL+endpoint, payload)
      .then(response => {
        response = response.json();
        return resolve(response);
      }).catch(error => { console.log([error, reject]); });
  });
}

export async function GetData(endpoint) {
  const BaseURL = process.env.REACT_APP_API_HOST;

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'GET',
      headers: {
        'Accept': "application/json",
        'Content-Type': "application/json"
      }
    };
    
    payload.headers.Authorization = "Bearer " + LocalStorageService.getToken();
                
    fetch(BaseURL+endpoint, payload)
      .then(response => {
        response = response.json();
        return resolve(response);
      }).catch(error => { console.log([error, reject]); });
  });
}

export async function DeleteData(endpoint) {
  const BaseURL = process.env.REACT_APP_API_HOST;

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'DELETE',
      headers: {
        'Accept': "application/json",
        'Content-Type': "application/json"
      }
    };

    payload.headers.Authorization = "Bearer " + LocalStorageService.getToken();

    fetch(BaseURL+endpoint, payload)
      .then(response => {
        response = response.json();
        return resolve(response);
      }).catch(error => { console.log([error, reject]); });
  });
}

export async function PostData(endpoint, data) {
  const BaseURL = process.env.REACT_APP_API_HOST;

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': "application/json", 
        'Content-Type': "application/json"
      }
    };
    
    payload.headers.Authorization = "Bearer " + LocalStorageService.getToken();

    fetch(BaseURL+endpoint, payload)
      .then(response => {
        response = response.json();
        return resolve(response);
      }).catch(error => { console.log([error, reject]); });
  });
}

export async function PutData(endpoint, data) {
  const BaseURL = process.env.REACT_APP_API_HOST;

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Accept': "application/json", 
        'Content-Type': "application/json"
      }
    };
    
    payload.headers.Authorization = "Bearer " + LocalStorageService.getToken();

    fetch(BaseURL+endpoint, payload)
      .then(response => {
        response = response.json();
        return resolve(response);
      }).catch(error => { console.log([error, reject]); });
  });
}
