import { Redirect } from 'react-router-dom';
import React from 'react';

class LocalStorageService {

  static getUser() {
    var user = localStorage.getItem('user');
    return JSON.parse(user);
  }

  static getToken() {
    const token = localStorage.getItem('token');
    return token;
  }

  static login(user) {
    //console.log(user.user);
    localStorage.setItem('token', user.token);
    localStorage.setItem('user', JSON.stringify(user.user));
    window.location.reload();
    return <Redirect to="/" />;
  }

  static logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload();
    return <Redirect to="/login" />;
  }

  static updateUserSettings(data) {
    const newData = { ...this.getUser(), settings: data }
    localStorage.setItem('user', JSON.stringify(newData));
  }

}

export default LocalStorageService;
