import React, { Component } from 'react';
import { AppBar, Toolbar, Button, Typography } from '@material-ui/core/';
import { withRouter, NavLink } from 'react-router-dom';
import './Header.scss';
import strings from '../../../lang/l10n';

class Header extends Component {
  render() {
    return <AppBar id="navbar">
      <Toolbar className="navigation-links">
        <Typography className="brand" variant="h4">
          <NavLink to="/" activeClassName="do-not-underline-this">
            <img alt="Oras Group Logo" src={process.env.PUBLIC_URL + "/orasgroup_logo.png"}/>
          </NavLink>
        </Typography>
        <div>
          <Button><NavLink to="/new">{strings.newJobTab}</NavLink></Button>
          <Button><NavLink to="/offers">{strings.offerArchiveTab}</NavLink></Button>
          <Button><NavLink to="/extrapages">{strings.extraPagesTab}</NavLink></Button>
          <Button><NavLink to="/settings">{strings.settingsTab}</NavLink></Button>
          <Button><NavLink to="/logout">{strings.logout}</NavLink></Button>
        </div>
      </Toolbar>
    </AppBar>;
  }
}

export default withRouter(Header);