import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../../partials/Header/Header.js';
import './Offers.scss';
import { Edit, Delete, ControlPointDuplicate } from '@material-ui/icons';
import APIService from '../../../services/APIService.js';
import { useTable, useRowSelect, useFilters, useGlobalFilter } from 'react-table'
import matchSorter from 'match-sorter';
import LocalStorageService from '../../../services/LocalStorageService.js';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core/';
import { compareDesc, parseISO, format } from 'date-fns';
import strings from '../../../lang/l10n';

class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offers: [],
      deleteConfirmation: false,
      redirect: null,
      selectedOfferId: false,
      showOnlyMine: false,
    };

    this.columns = [
      {
        Header: 'Offers',
        columns: [
          {
            Header: strings.offerArchive.id,
            accessor: 'id'
          },
          {
            Header: strings.offerArchive.name,
            accessor: 'name',
          },
          {
            Header: strings.offerArchive.customerNumber,
            accessor: 'customer_number'
          },
          {
            Header: strings.offerArchive.customer,
            accessor: 'customer'
          },
          {
            Header: strings.offerArchive.date,
            accessor: (o) => format(parseISO(o.date), 'dd.MM.yyyy')
          },
          {
            Header: strings.offerArchive.author,
            accessor: 'user.name',
          },
        ],
      },
    ];
  }

  componentDidMount() {
    APIService.getOffers()
      .then((offers) => {
        if (offers) {
          offers = offers.sort((a, b) => {
            return compareDesc(
              parseISO(a.date),
              parseISO(b.date)
            );
          });

          this.setState({ offers });
        }
      });
  }

  userCanEdit = (offer) => {
    let user = LocalStorageService.getUser();
    return offer.user_id === user.id || user.is_admin;
  }

  editOffer = () => {
    APIService.getOffer(this.state.selectedOfferId)
      .then((offer) => {
        //console.log(offer);
        // Add ID to query if doesn't exist, /new will update offer only if the query contains ID.
        let query = JSON.parse(offer.query);
        if (!query.id) {
          query.id = offer.id;
        }

        let redirect = <Redirect to={{
          pathname: '/new',
          state: { offer: query }
        }}/>
        this.setState({ redirect });
      });
  }

  duplicateOffer = () => {
    APIService.getOffer(this.state.selectedOfferId)
      .then((offer) => {
        let userSettings = LocalStorageService.getUser().settings || {};
        let salesDetails = {
          salesContactName: userSettings.salesContactName || '',
          salesContactTel: userSettings.salesContactTel || '',
          salesContactMobile: userSettings.salesContactMobile || '',
          salesContactEmail: userSettings.salesContactEmail || ''
        }

        // Merge user's sales details with offer query
        let query = Object.assign({}, JSON.parse(offer.query), salesDetails);
        delete query.id;

        let redirect = <Redirect to={{
          pathname: '/new',
          state: { offer: query }
        }}/>

        this.setState({ redirect });
      });
  };

  showDeleteConfirmation = () => {
    this.setState({ deleteConfirmation: true });
  };

  hideDeleteConfirmation = () => {
    this.setState({ deleteConfirmation: false });
  }

  deleteOffer = () => {
    APIService.deleteOffer(this.state.selectedOfferId).then((result) => {
      this.setState(prevState => ({ offers: prevState.offers.filter(offer => offer.id !== prevState.selectedOfferId), selectedOfferId: false }));
      this.hideDeleteConfirmation();
    }).catch(error => { console.log(error); });
  };

  setselectedOfferId = (selectedOfferId) => {
    this.setState({ selectedOfferId });
  }

  toggleShowOnlyMine = () => {
    this.setState((oldState) => {
      return { showOnlyMine: !oldState.showOnlyMine };
    })
  }

  render() {
    if (this.state.redirect) {
      return this.state.redirect;
    }

    const user = LocalStorageService.getUser();

    return <Container id="mainview">
      <Header/>
      <Box className="content" style={{ maxWidth: '100%' }}>
        <ArchiveTable
          offers={this.state.offers}
          setselectedOfferId={this.setselectedOfferId}
          selectedOfferId={this.state.selectedOfferId}
          columns={this.columns}
          data={this.state.offers.filter(x => this.state.showOnlyMine ? x.user_id === user.id : true)}
          editOffer={this.editOffer}
          duplicateOffer={this.duplicateOffer}
          deleteOffer={this.showDeleteConfirmation}
          toggleShowOnlyMine={this.toggleShowOnlyMine}
          showOnlyMine={this.state.showOnlyMine}
        />
      </Box>

      <Dialog
        open={this.state.deleteConfirmation}
        onClose={this.hideDeleteConfirmation}
      >
        <DialogTitle>{strings.offerArchive.deleteModal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {strings.offerArchive.deleteModal.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.hideDeleteConfirmation} color="primary">
            {strings.offerArchive.deleteModal.cancel}
          </Button>
          <Button onClick={this.deleteOffer} color="secondary" autoFocus>
            {strings.offerArchive.deleteModal.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>;
  }
}

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const count = preGlobalFilteredRows.length;

  return <TextField
    label={strings.offerArchive.searchOffers}
    type="search"
    placeholder={`${count} ${strings.offerArchive.records}...`}
    InputLabelProps={{ shrink: true }}
    fullWidth
    onChange={e => {
      setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
    }}
  />
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

const ArchiveTable = (props) => {
  const { offers, columns, data, setselectedOfferId, selectedOfferId, editOffer, deleteOffer, duplicateOffer, showOnlyMine, toggleShowOnlyMine } = props;

  const filterTypes = React.useMemo(() => ({
    fuzzyText: fuzzyTextFilterFn,
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
          : true;
        })
      },
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, preGlobalFilteredRows, setGlobalFilter, state } = useTable({
    columns,
    data,
    filterTypes,
  },
    useFilters,
    useGlobalFilter,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        {
          id: 'selection',
          Header: strings.offerArchive.select,
          Cell: ({ row }) => (
            <div>
              <input type="radio" name="selectOffer" onClick={() => setselectedOfferId(row.values.id)}/>
            </div>
          ),
        },
        ...columns,
      ])
    }
  );

  const userId = LocalStorageService.getUser().id;
  const userCanEdit = offers.filter((offer) => offer.id === selectedOfferId && offer.user_id === userId).length > 0;

  return <Paper>
    <Toolbar>
      {selectedOfferId > 0 ? (
        <Typography color="inherit" variant="h6">
          {strings.offerArchive.selectedOffer}: {selectedOfferId}
        </Typography>
      ) : (
        <Typography variant="h6">
          {strings.offerArchive.offerArchive}
        </Typography>
      )}

      {selectedOfferId && <div style={{ position: 'absolute', right: '10px' }}>
        <Tooltip title={strings.offerArchive.cloneOffer} placement="top">
          <IconButton size="medium" onClick={duplicateOffer}>
            <ControlPointDuplicate />
          </IconButton>
        </Tooltip>
        {userCanEdit ?
          (<Tooltip title={strings.offerArchive.edit} placement="top">
            <IconButton size="medium" onClick={editOffer}>
              <Edit />
            </IconButton>
          </Tooltip>) : ''
        }
        {userCanEdit ?
          (<Tooltip title={strings.offerArchive.delete} placement="top">
            <IconButton size="medium" onClick={deleteOffer}>
              <Delete />
            </IconButton>
          </Tooltip>) : ''
        }
      </div>
      }
    </Toolbar>
    <TableContainer style={{ maxHeight: '500px' }}>
      <Table {...getTableProps()}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={allColumns.length - 1}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </TableCell>
            <TableCell colSpan={allColumns.length - 1}>
              <FormControlLabel control={<Switch checked={showOnlyMine} onChange={toggleShowOnlyMine} color="primary" />} label={strings.offerArchive.showOnlyMine} />
            </TableCell>
          </TableRow>
          {headerGroups.slice(1).map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
              return <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                })}
                </TableRow>
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>;
}

export default Offers;
