import React, { Component } from 'react';
import Header from '../../partials/Header/Header.js';
import './Settings.scss';
import { Container, Box, Grid, Typography, InputLabel, Select, MenuItem, Button, TextField } from '@material-ui/core/';
import SettingsIcon from '@material-ui/icons/Settings';
import APIService from '../../../services/APIService.js';
import LocalStorageService from '../../../services/LocalStorageService.js';
import strings from '../../../lang/l10n';
import Languages from '../../../data/languages';
import { AccountCircle } from '@material-ui/icons';

const outputTypes = {
  pdf: 'PDF',
  xlsx: 'XLSX',
};

const templateTypes = {
  proposal: 'Proposal',
  offer: 'Offer'
};

const btnStyles = {
  borderRadius: 0,
  border: '2px solid black',
  color: '#000',
  padding: '0.5em 4em',
  fontWeight: 500,
};

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outputType: '',
      templateType: '',
      salesContactName: '',
      salesContactTel:  '',
      salesContactEmail: '',
      salesContactMobile:  '',
      defaultMarket: '',
      defaultLanguage: '',
      interfaceLanguage: 'en',
      countries: [],

      current_password: "",
      new_password: ""
    };
  }

  componentDidMount = () => {
    const settings = LocalStorageService.getUser().settings || {};
    //console.log(settings);
    this.setState(settings);

    APIService.getCountries()
      .then((countries) => {
        this.setState({countries})
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleInterfaceLanguageChange = (e) => {
    strings.setLanguage(e.target.value);
    this.handleChange(e);
  }

  updateSettings = () => {
    APIService.updateSettings(this.state).then((result) => {
      if(result) {
        LocalStorageService.updateUserSettings(this.state);
      } else {
        console.log('updateSettings failed');
      }
    });
  }

  getLanguageItems = () => {
    let brandLanguages = Languages[LocalStorageService.getUser().brand_id];
    return Object.keys(brandLanguages).map((key) => <MenuItem value={brandLanguages[key]}>{key}</MenuItem>);
  }

  getMarketItems = () => {
    return this.state.countries.map((country) => <MenuItem value={country.code}>{country.name}</MenuItem>)
  }

  render() {
    return <Container id="mainview" className="new">
      <Header />
      <Box className="content">
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <SettingsIcon fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6">
              {strings.settingsPage.settings}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid container item xs={6} spacing={3}>
            <Grid item xs={12}>
              <TextField fullWidth name="salesContactName" label={strings.settingsPage.salesName} onChange={this.handleChange} value={this.state.salesContactName}/>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth name="salesContactTel" label={strings.settingsPage.salesTel} onChange={this.handleChange} value={this.state.salesContactTel}/>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth name="salesContactMobile" label={strings.settingsPage.salesMobile} onChange={this.handleChange} value={this.state.salesContactMobile}/>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth name="salesContactEmail" label={strings.settingsPage.salesEmail} onChange={this.handleChange} value={this.state.salesContactEmail}/>
            </Grid>

            <Grid item xs={12}>
              <Button variant="outlined" style={btnStyles} onClick={this.updateSettings} >{strings.settingsPage.save}</Button>
            </Grid>
          </Grid>

          <Grid container item xs={6} spacing={3}>
            <Grid item xs={12}>
              <InputLabel id="outputTypeLabel">{strings.settingsPage.defaultOutput}</InputLabel>
              <Select fullWidth id="outputType" onChange={this.handleChange} name="outputType" labelId="outputTypeLabel" value={this.state.outputType}>
                <MenuItem value={outputTypes.pdf}>PDF</MenuItem>
                <MenuItem value={outputTypes.xlsx}>XLSX (Excel)</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="templateTypeLabel">{strings.settingsPage.defaultTemplate}</InputLabel>
              <Select fullWidth id="templateType" onChange={this.handleChange} name="templateType" labelId="templateTypeLabel" value={this.state.templateType}>
                <MenuItem value={templateTypes.offer}>Offer</MenuItem>
                <MenuItem value={templateTypes.proposal}>Proposal</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="languageLabel">{strings.settingsPage.defaultLanguage}</InputLabel>
              <Select fullWidth id="defaultLanguage" onChange={this.handleChange} name="defaultLanguage" labelId="languageLabel" value={this.state.defaultLanguage}>
                { this.getLanguageItems() }
              </Select>
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="marketLabel">{strings.settingsPage.defaultMarket}</InputLabel>
              <Select fullWidth id="defaultMarket" onChange={this.handleChange} name="defaultMarket" labelId="marketLabel" value={this.state.defaultMarket}>
                { this.getMarketItems() }
              </Select>
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="templateTypeLabel">{strings.settingsPage.interfaceLanguage}</InputLabel>
              <Select fullWidth id="interfaceLanguage" onChange={this.handleInterfaceLanguageChange} name="interfaceLanguage" labelId="templateTypeLabel" value={this.state.interfaceLanguage}>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="de">German</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{marginTop: "40px"}}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <AccountCircle fontSize="large"/>
            </Grid>
            <Grid item>
              <Typography variant="h6">
              {strings.settingsPage.change_password}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={6} spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth type="password" name="current_password" label={strings.settingsPage.current_password} onChange={this.handleChange} value={this.state.current_password}/>
          </Grid>

          <Grid item xs={12}>
            <TextField fullWidth type="password" name="new_password" label={strings.settingsPage.new_password} onChange={this.handleChange} value={this.state.new_password}/>
          </Grid>

          <Grid item xs={12}>
            <Button variant="outlined" style={btnStyles} onClick={this.updatePassword} >{strings.settingsPage.save}</Button>
          </Grid>
        </Grid>
      </Box>
    </Container>;
  }
}

export default Settings;
