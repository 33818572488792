import React, { Component } from 'react';
import { Container, Button, Typography, Avatar, TextField } from '@material-ui/core/';
import { PostDataUnauthenticated } from '../../../services/RequestService.js';
import LocalStorageService from '../../../services/LocalStorageService.js';
import LockSharp from '@material-ui/icons/LockSharp';
import { withStyles } from '@material-ui/core/styles';
import strings from '../../../lang/l10n';

const styles = theme => ({
  bg: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 0,
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
});

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      password: "",
      email: "",
      error: false,
    };
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  onFormSubmit = (e) => {
    e.preventDefault();
    const payload = { email: this.state.email, password: this.state.password };
    PostDataUnauthenticated("login", payload).then((result) => {
      if (result.token) {
        LocalStorageService.login(result);
      } else {
        this.setState({ error: true });
      }
    });
  }

  render() {
    const { classes } = this.props;
    return <Container id="mainview" maxWidth="xs">
      <div className={classes.bg}>
        <Avatar className={classes.avatar}>
          <LockSharp/>
        </Avatar>
        <Typography component="h1" variant="h5">
          POB Tool
        </Typography>
        <form className={classes.form} noValidate onSubmit={this.onFormSubmit}>
          <TextField
            error={this.state.error}
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={strings.email}
            name="email"
            type="email"
            autoFocus
            onChange={this.onChange}
            value={this.state.user}
          />
          <TextField
            error={this.state.error}
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={strings.password}
            type="password"
            id="password"
            onChange={this.onChange}
            value={this.state.password}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
            type="submit"
          >
            {strings.login}
          </Button>
        </form>
      </div>
    </Container>;
  }
}

export default withStyles(styles)(Login)
