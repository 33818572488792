import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../partials/Header/Header.js';
import './Offers.scss';
import CoverPageTab from './Tabs/CoverPageTab.js';
import ProductsTab from './Tabs/ProductsTab.js';
import FinalizeTab from './Tabs/FinalizeTab.js';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  withStyles,
  Typography,
} from '@material-ui/core/';
import APIService from '../../../services/APIService.js';
import LocalStorageService from '../../../services/LocalStorageService.js';
import CountriesISO3 from '../../../data/countries-iso3.js';
import CountriesCurrency from '../../../data/countries-currency.js';
import Languages from '../../../data/languages.js';
import strings from '../../../lang/l10n';

const CreateOfferTabs = {
  coverPage: 1,
  products: 2,
  finalize: 3,
};

const styles = theme => ({
  btnFilled: {
    borderRadius: 0,
    color: '#f5f5f5',
    padding: '0.7em 4em',
    fontWeight: 600,
    backgroundColor: '#4d4d4d',
    [`&:hover`]: {
      backgroundColor: '#4d4d4d',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class OffersNew extends Component {
  constructor(props) {
    super(props);

    let userSettings = LocalStorageService.getUser().settings || {};

    this.state = {
      tab: CreateOfferTabs.coverPage,
      countries: [],
      offer: {
        /* Cover page tab */
        coverPageTitle: '',
        coverPageDate: null,
        offerValidUntil: null,
        customerNumber: '',
        coverPageCustomerName: '',
        coverPageAddress1: '',
        coverPageAddress2: '',
        coverPageExtraInfo: '',
        coverPageFiles: [],
        salesContactName: userSettings.salesContactName || '',
        salesContactTel: userSettings.salesContactTel || '',
        salesContactMobile: userSettings.salesContactMobile || '',
        salesContactEmail: userSettings.salesContactEmail || '',
        settingsLanguage: userSettings.defaultLanguage || null,
        settingsMarket: userSettings.defaultMarket || LocalStorageService.getUser().country.code,

        /* Products Tab */
        showEAN: false,
        showHVAC: false,
        showTechnicalData: false,
        includeGrossPrice: true,
        includeNetPrice: false,
        endPriceWithVAT: true,
        useOncomingPrice: true,
        // show3DViewQR: false,
        includeTotalPrice: false,
        customerDiscountPercentage: 0,
        projectOrOfferDiscount: 0,
        showDiscountedPrice: false,
        showDiscountInPercentage: false,
        showDiscountOnTotalPriceOnly: false,
        products: [],

        /* Finalize Tab */
        offerTemplate: userSettings.templateType || 'Proposal',
        outputType: userSettings.outputType || 'PDF',
        tableOfContents: [],
        pagesOrder: [],
      },
      saveError: false,
      saveOk: false,
      loadingOverlay: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    APIService.getBrand().then((brand) => {
      APIService.getCountries().then((countries) => {
        let offer = this.state.offer;

        if (this.props.location && this.props.location.state && this.props.location.state.offer) {
          offer = Object.assign({}, this.state.offer, this.props.location.state.offer)
        }

        this.setState({
          brand,
          offer,
          countries,
          loading: false
        });
      });
    });
  }

  changeTab = (e, tab) => {
    this.setState({ tab });
  };

  toggleOfferCheckbox = (key) => {
    this.setState((oldState) => {
      const newOffer = Object.assign({}, oldState.offer);
      newOffer[key] = !oldState.offer[key];

      newOffer.products.map((product) => { return this.recalculateProductPrice(product, newOffer); });

      return {
        offer: newOffer
      };
    });
  }

  handleOfferTextfieldChange = (key, value) => {
    this.setState((oldState) => {
      const newOffer = Object.assign({}, oldState.offer);
      newOffer[key] = value;

      newOffer.products.map((product) => { return this.recalculateProductPrice(product, newOffer); });

      //console.log(newOffer.products);
      return {
        offer: newOffer
      };
    });
  }

  recalculateProductPrice(product, offer) {
    if (!offer) {
      offer = this.state.offer;
    }

    let price = 0;
    let countryCode = CountriesISO3[offer.settingsMarket.toUpperCase()];
    product.currency = CountriesCurrency[offer.settingsMarket.toUpperCase()];

    if (offer.useOncomingPrice) {
      if (product["OncomingPriceWithoutVAT" + countryCode]) {
        price = product.priceWithoutVAT = product["OncomingPriceWithoutVAT" + countryCode];
        product.priceWithVAT = product["OncomingPriceWithVAT" + countryCode]
      } else {
        // TODO: Better handling unpriced items, notify the user?
        price = product.priceWithoutVAT = 0;
        product.priceWithVAT = 0;
      }
    } else {
      if (product["PriceWithoutVAT" + countryCode]) {
        price = product.priceWithoutVAT = product["PriceWithoutVAT" + countryCode];
        product.priceWithVAT = product["PriceWithVAT" + countryCode]
      } else {
        // TODO: Better handling unpriced items, notify the user?
        price = product.priceWithoutVAT = 0;
        product.priceWithVAT = 0;
      }
    }

    if (typeof price === 'string') {
      price = parseFloat(price.match(/(.+)\W(.+)/));
    }

    if (isNaN(price)) {
      price = 0;
    }

    // If discount hasn't been added then default value to 0
    let customerDiscountPer = parseInt(offer.customerDiscountPercentage || 0);

    // Calculate total discount, limit max to 100
    let totalDiscount = Math.min(
      customerDiscountPer,
      100
    );

    // Calculate product pricing with discount
    product.originalPrice = price;
    product.totalDiscount = totalDiscount;
    product.priceDiscount = (price * (totalDiscount / 100)).toFixed(2);
    product.amount = product.amount || 1;

    if (totalDiscount > 0) {
      price -= product.priceDiscount;
    }

    product.price = price.toFixed(2);
    return product;
  }

  // NOTE! There is a very real possibility of race conditions or things working in weird orders as there is no "loading" state or synchronicity here.
  toggleOfferProduct = (product) => {
    const newOffer = Object.assign({}, this.state.offer);
    this.recalculateProductPrice(product);

    if (!newOffer.products) {
      newOffer.products = [];
    }

    newOffer.products.push(product);
    this.setState({ offer: newOffer });
  }

  removeOfferProduct = (index) => {
    const newOffer = Object.assign({}, this.state.offer);
    newOffer.products.splice(index, 1);
    this.setState({ offer: newOffer });
  }

  updateOfferProduct = (product, key, value) => {
    this.setState((oldState) => {
      const newOffer = Object.assign({}, oldState.offer);

      newOffer.products = newOffer.products.map(prod => {
        if (prod.ItemCode !== product.ItemCode) {
          return prod;
        }

        const newProduct = Object.assign({}, prod);
        newProduct[key] = value;
        return newProduct;
      });

      return {
        offer: newOffer
      };
    });
  }

  updateOfferProductAtIndex = (index, product, key, value) => {
    this.setState((oldState) => {
      const newOffer = Object.assign({}, oldState.offer);
      const newProduct = Object.assign({}, newOffer.products[index]);
      newProduct[key] = value;
      newOffer.products[index] = newProduct;

      return {
        offer: newOffer
      };
    });
  }

  updateTableOfContents = (tableOfContents) => {
    //console.log("C", tableOfContents);
    let pagesOrder = tableOfContents.map((page) => page.id);

    this.setState(prevState => ({
      offer: {
        ...prevState.offer,
        tableOfContents,
        pagesOrder
      },
    }));
  }

  updateProductUIDs = () => {
    this.setState((oldState) => {
      const newOffer = Object.assign({}, oldState.offer);
      newOffer.products.forEach((product, index) => {
        product.uid = index;
      })

      return {
        offer: newOffer
      }
    })
  };

  getProductImageSrc = product => {
    // This logic is adapted from the Core;
    // See hansa-pob-back/app/Services/Elasticsearch/Product.php -- getPhotoAttribute()

    const types = [
        'CatalogRender',
        'ProductPhoto',
        'CatalogDrawing',
        'AmbientPhoto'
    ];

    if (product.Photo) {
      let target = false;

      types.forEach(type => {
        if (product.Photo[type]) {
          target = product.Photo[type].url;
        }
      });

      if (target) { return target; }
    }

    // Last backup
    if (product.TechnicalImage && product.TechnicalImage['CatalogDrawing']) {
        return product.TechnicalImage['CatalogDrawing']['url'];
    }

    return null;
  }

  hideLoadingOverlay = () => { this.setState({ loadingOverlay: false }); }

  saveOffer = (triggerOverlay, callback) => {
    if (this.state.loading) { return; }

    this.setState({ loading: true, loadingOverlay: triggerOverlay === true }, () => {
      let call;
      if (this.state.offer.id) {
        call = APIService.updateOffer(this.state.offer.id, this.state.offer);
      } else {
        call = APIService.createOffer(this.state.offer);
      }

      call.then(result => {
        let offer = this.state.offer;

        if (result && !result.error) {
          offer.id = result.id;
        }

        this.setState({ offer, saveError: result.error, saveOk: !result.error }, callback);
      }).catch(error => {
        this.setState({ saveError: 1, saveOk: false }, () => { callback(error); });
      }).finally(() => this.setState({loading: false}))
    });
  }

  render() {
    const { classes } = this.props;
    const { countries } = this.state;
    const brandLanguages = Languages[LocalStorageService.getUser().brand_id];

    return <Container id="mainview" className="new">
      <Backdrop className={classes.backdrop} open={this.state.loadingOverlay}>
        <CircularProgress color="inherit" />
        <Typography variant="h6">&nbsp;Loading</Typography>
      </Backdrop>

      <Header />
        <Tabs className="offer-tabs" value={this.state.tab} onChange={this.changeTab}>
          <Tab value={CreateOfferTabs.coverPage} label={strings.coverPageSettingsTab} />
          <Tab value={CreateOfferTabs.products} label={strings.productsTab} />
          <Tab value={CreateOfferTabs.finalize} label={strings.finalizeTab} />
        </Tabs>
        <Divider />
        <Box className="content">
          {this.state.loading && <Grid container direction="column" justify="center" alignItems="center"><CircularProgress /></Grid>}
          {!this.state.loading && <>
            {this.state.tab === CreateOfferTabs.coverPage && <CoverPageTab
              offerLanguages={brandLanguages}
              offerMarkets={countries}
              offer={this.state.offer}
              toggleOfferCheckbox={this.toggleOfferCheckbox}
              handleOfferTextfieldChange={this.handleOfferTextfieldChange}
              toggleOfferProduct={this.toggleOfferProduct}
              updateOfferProduct={this.updateOfferProduct}
            />}
            {this.state.tab === CreateOfferTabs.products && <ProductsTab
              brand={this.state.brand}
              offer={this.state.offer}
              toggleOfferCheckbox={this.toggleOfferCheckbox}
              handleOfferTextfieldChange={this.handleOfferTextfieldChange}
              toggleOfferProduct={this.toggleOfferProduct}
              removeOfferProduct={this.removeOfferProduct}
              updateOfferProduct={this.updateOfferProduct}
              updateOfferProductAtIndex={this.updateOfferProductAtIndex}
              getProductImageSrc={this.getProductImageSrc}
            />}
            {this.state.tab === CreateOfferTabs.finalize && <FinalizeTab
              offer={this.state.offer}
              updateTableOfContents={this.updateTableOfContents}
              handleOfferTextfieldChange={this.handleOfferTextfieldChange}
              getProductImageSrc={this.getProductImageSrc}
              saveOffer={this.saveOffer}
              hideLoadingOverlay={this.hideLoadingOverlay}
              updateProductUIDs={this.updateProductUIDs}
            />}

          <Grid item xs={12}>
            <Button variant="contained" className={classes.btnFilled} onClick={this.saveOffer}>{strings.save}</Button>
            {this.state.saveError && <>
              <Chip
                label={strings.saveError}
                onDelete={() => { this.setState({ saveError: false }); }}
                color="secondary"
                style={{ marginLeft: 15 }}
              />
            </>}
            {this.state.saveOk && <>
              <Chip
                label={strings.saveOk}
                onDelete={() => { this.setState({ saveError: false }); }}
                color="primary"
                style={{ marginLeft: 15 }}
              />
            </>}
          </Grid>
          </>}
        </Box>
    </Container>;
  }
}

export default withStyles(styles)(withRouter(OffersNew));
