import LocalStorageService from "./LocalStorageService";

class FilemanagerService {
    static getUrl() {
        let userToken = btoa(LocalStorageService.getToken());
        return process.env.REACT_APP_FILEMANAGER_URL + "?t=" + userToken;
    }

    static open() {
        window.open(FilemanagerService.getUrl(), 'fm', 'width=1400,height=800');
    }

    static select() {
        return new Promise((resolve, reject) => {
            window.addEventListener('message', (event) => {
                if (event && event.data && 'fileUrl' in event.data) {
                    resolve(event.data.fileUrl);
                }
            }, false);

            FilemanagerService.open();
        });
    }
}

export default FilemanagerService;