import React, { Component } from 'react';
import { FixedSizeList } from 'react-window';
import APIService from '../../../../services/APIService.js';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconAddCircle from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import IconCardGiftcard from '@material-ui/icons/CardGiftcard';
import IconDelete from '@material-ui/icons/Delete';
import IconEuro from '@material-ui/icons/Euro';
import IconSearch from '@material-ui/icons/Search';
import IconSettings from '@material-ui/icons/Settings';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import strings from '../../../../lang/l10n';
import CountriesISO3 from '../../../../data/countries-iso3';

export default class ProductsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: false,
      searching: false,
      firstSearchDone: false,
      searchResults: [],
      searchResultsTotal: 0,
      activeProduct: false,
      activeProductIndex: null,
      queryString: ""
    };
  }

  searchChangeHandler = (e) => {
    if (this.state.timeout) {
      window.clearTimeout(this.state.timeout);
    }

    const queryString = e.target.value;

    const timeout = window.setTimeout(() => {
      this.setState({ queryString }, () => {
        let market = this.props.offer.settingsMarket.toUpperCase();
        market = CountriesISO3[market];

        APIService.search(this.state.queryString, market).then((result) => {
          this.setState({
            firstSearchDone: true,
            timeout: false,
            searching: false,
            searchResults: result || [],
            searchResultsTotal: result ? result.length : 0,
          });
        });
      });
    }, 1000);

    this.setState({ timeout, searching: true });
  }

  productClick = (product) => {
    this.props.toggleOfferProduct(product);
  }

  removeProduct = (index) => {
    this.props.removeOfferProduct(index);
    this.setState({update: index})
  }

  showProductDetails = (e, index) => {
    // We need fresh data.
    const freshProductData = this.props.offer.products[index];

    const selected = document.querySelector('.Mui-selected');
    if (selected) {
      selected.classList.remove('Mui-selected');
    }

    e.target.classList.add('Mui-selected');
    this.setState({ activeProduct: freshProductData, activeProductIndex: index });
  }

  toggleChecbox = name => event => {
    this.props.toggleOfferCheckbox(name);
  };

  render() {
    let activeProductReference = null;

    if (this.state.activeProduct) {
      // This is necessary because MUI gets confused with undefined value properties
      activeProductReference = this.props.offer.products[this.state.activeProductIndex];
      activeProductReference.projectCode = activeProductReference.projectCode || "";
      activeProductReference.amount = activeProductReference.amount || "";
      activeProductReference.additionalInformation = activeProductReference.additionalInformation || "";
    }

    return <div>
      <Grid container spacing={10}>
        <Grid item xs={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IconSettings fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6">{strings.productsSettings.generalSettings}</Typography>
            </Grid>
          </Grid>

          <FormControl>
            <FormLabel component="legend">{strings.productsSettings.productCodes}</FormLabel>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.showEAN}
                  onChange={this.toggleChecbox("showEAN")}
                />
              }
              label={strings.productsSettings.showEAN}
              labelPlacement="end"
            />

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.showHVAC}
                  onChange={this.toggleChecbox("showHVAC")}
                />
              }
              label={strings.productsSettings.showHVAC}
              labelPlacement="end"
            />

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.showTechnicalData}
                  onChange={this.toggleChecbox("showTechnicalData")}
                />
              }
              label={strings.productsSettings.showTechnicalData}
              labelPlacement="end"
            />

            {/* <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.show3DViewQR}
                  onChange={this.toggleChecbox("show3DViewQR")}
                />
              }
              label={strings.productsSettings.show3DViewQR}
              labelPlacement="end"
            /> */}
          </FormControl>
        </Grid>

        <Grid item xs={4} style={{ borderRight: '0.1em solid LightGrey', borderLeft: '0.1em solid LightGrey' }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IconEuro fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6">{strings.productsSettings.price}</Typography>
            </Grid>
          </Grid>

          <FormControl>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.includeGrossPrice}
                  onChange={this.toggleChecbox("includeGrossPrice")}
                />
              }
              label={strings.productsSettings.includeGrossPrice}
              labelPlacement="end"
            />

            <FormControlLabel
              value="end"
              style={{ display: 'none' }}
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.includeNetPrice}
                  onChange={this.toggleChecbox("includeNetPrice")}
                />
              }
              label={strings.productsSettings.includeNetPrice}
              labelPlacement="end"
            />

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.endPriceWithVAT}
                  onChange={this.toggleChecbox("endPriceWithVAT")}
                />
              }
              label={strings.productsSettings.endPriceWithVAT}
              labelPlacement="end"
            />

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.useOncomingPrice}
                  onChange={this.toggleChecbox("useOncomingPrice")}
                />
              }
              label={strings.productsSettings.useOncomingPrice}
              labelPlacement="end"
            />

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.includeTotalPrice}
                  onChange={this.toggleChecbox("includeTotalPrice")}
                />
              }
              label={strings.productsSettings.includeTotalPrice}
              labelPlacement="end"
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IconCardGiftcard fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h6">{strings.productsSettings.discounts}</Typography>
            </Grid>
          </Grid>

          <FormLabel component="legend">{strings.productsSettings.assignResponsibility}</FormLabel>
          <TextField
            fullWidth
            label={`${strings.productsSettings.customerDiscount} %`}
            style={{ marginTop: 15 }}
            type="number"
            value={this.props.offer.customerDiscountPercentage}
            onChange={(e) => {
              this.props.handleOfferTextfieldChange("customerDiscountPercentage", e.target.value);
            }}
          />
          <TextField
            fullWidth
            label={`${strings.productsSettings.projectDiscount} %`}
            style={{ marginTop: 15 }}
            type="number"
            value={this.props.offer.projectOrOfferDiscount}
            onChange={(e) => {
              this.props.handleOfferTextfieldChange("projectOrOfferDiscount", e.target.value);
            }}
          />

          <FormControl>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.showDiscountedPrice}
                  onChange={this.toggleChecbox("showDiscountedPrice")}
                />
              }
              label={strings.productsSettings.showDiscountedPrice}
              labelPlacement="end"
            />
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.showDiscountInPercentage}
                  onChange={this.toggleChecbox("showDiscountInPercentage")}
                />
              }
              label={strings.productsSettings.showDiscountInPer}
              labelPlacement="end"
            />
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.offer.showDiscountOnTotalPriceOnly}
                  onChange={this.toggleChecbox("showDiscountOnTotalPriceOnly")}
                />
              }
              label={strings.productsSettings.showDiscountOnTotal}
              labelPlacement="end"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 40, marginBottom: 40 }} />

      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item><IconAddCircle fontSize="large" /></Grid>
            <Grid item>
              <Typography variant="h6">{strings.productsSettings.addProducts}</Typography>
            </Grid>
          </Grid>

          <FormControl fullWidth>
            <InputLabel>
              {strings.productsSettings.productSearch}
              {this.state.firstSearchDone && <>
                &nbsp;- {strings.productsSettings.showing} {this.state.searchResults.length} {strings.productsSettings.searchResults} {this.state.searchResultsTotal}
              </>}
            </InputLabel>
            <Input
              type={'text'}
              onChange={this.searchChangeHandler}
              endAdornment={
                <InputAdornment position="end">
                  <IconSearch />
                </InputAdornment>
              }
            />
          </FormControl>

          {this.state.searching && <>
            {strings.productsSettings.searching}
          </>}
          {!this.state.searching &&
            <FixedSizeList
              height={150}
              itemSize={50}
              itemCount={this.state.searchResults.length}
              itemData={this.state.searchResults}
            >
              {({ index, style }) => (
                <ListItem button style={style} key={index} onClick={() => { this.productClick(this.state.searchResults[index]); }}>
                  <ListItemIcon>
                    <div style={{
                      backgroundColor: '#fff',
                      backgroundImage: "url('" + this.props.getProductImageSrc(this.state.searchResults[index]) + "')",
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center center',
                      width: '50px',
                      height: '50px',
                      border: '3px solid #fff',
                      marginRight: '15px'
                    }}>
                    </div>
                  </ListItemIcon>
                  <ListItemText
                    data-code={this.state.searchResults[index].ItemCode}
                    primary={
                      <>
                        <i style={{ width: '140px', display: 'inline-block' }}>{this.state.searchResults[index].ItemCode}</i>
                        {this.state.searchResults[index].ItemFamily + " " + this.state.searchResults[index].ItemName.LocaleString[this.props.offer.settingsLanguage]}
                      </>
                    }
                  />
                </ListItem>
              )}
            </FixedSizeList>
          }
        </Grid>

        {this.props.offer.products && this.props.offer.products.length > 0 && <>
          <Grid item xs={6}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="body1">{strings.productsSettings.selectedProducts}</Typography>
              </Grid>
            </Grid>

            <List
              dense
              style={{ backgroundColor: "#fff", padding: "8px", margin:"2px", border: "1px solid #ccc", borderRadius: "3px" }}
            >
              {this.props.offer.products.map((product, index) => {
                return <ListItem
                  button key={product.ItemCode}
                  onClick={(e) => { this.showProductDetails(e, index); }}
                  selected={this.state.activeProduct && this.state.activeProductIndex === index}
                >
                  <ListItemIcon>
                    <div style={{
                      backgroundColor: '#fff',
                      backgroundImage: "url('" + this.props.getProductImageSrc(product) + "')",
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center center',
                      width: '50px',
                      height: '50px',
                      border: '3px solid #fff',
                      marginRight: '15px'
                    }}>
                    </div>
                  </ListItemIcon>
                  <ListItemText
                    data-code={product.ItemCode}
                    primary={
                      <>
                        <i style={{ width: '80px', display: 'inline-block' }}>{product.ItemCode}</i>
                        {product.ItemFamily + " " + product.ItemName.LocaleString[this.props.offer.settingsLanguage]}<br/>
                        <i>{strings.productsSettings.Price}: {product.userPrice || product.price}</i>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="deselect product"
                      onClick={() => {
                        if (activeProductReference && this.state.activeProductIndex === index) {
                          this.setState({ activeProduct: false, activeProductIndex: null }, () => { this.removeProduct(index); });
                        } else {
                          this.removeProduct(index);
                        }
                      }}
                    >
                      <IconDelete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              })}
            </List>
          </Grid>

          {this.state.activeProduct && <>
            <Grid item xs={6}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography variant="body1">
                  {strings.productsSettings.outputSelection}
                  </Typography>
                </Grid>
              </Grid>

              <TextField
                fullWidth
                name="projectCode"
                label={strings.productsSettings.projectCode}
                style={{ marginTop: 15 }}
                value={activeProductReference.projectCode}
                onChange={(e) => { this.props.updateOfferProductAtIndex(this.state.activeProductIndex, activeProductReference, "projectCode", e.target.value); }}
              />
              <TextField
                fullWidth
                name="amount"
                label={strings.productsSettings.amount}
                style={{ marginTop: 15 }}
                type="number"
                value={activeProductReference.amount}
                onChange={(e) => { this.props.updateOfferProductAtIndex(this.state.activeProductIndex, activeProductReference, "amount", e.target.value); }}
              />
              <TextField
                fullWidth
                type="number"
                name="userPrice"
                label={strings.productsSettings.price}
                style={{ marginTop: 15 }}
                value={activeProductReference.userPrice || activeProductReference.price}
                onChange={(e) => { this.props.updateOfferProductAtIndex(this.state.activeProductIndex, activeProductReference, "userPrice", e.target.value); }}
              />
              <TextField
                fullWidth
                label={strings.productsSettings.additionalInformation}
                multiline
                rows="4"
                variant="outlined"
                style={{ marginTop: 25 }}
                value={activeProductReference.additionalInformation}
                onChange={(e) => { this.props.updateOfferProductAtIndex(this.state.activeProductIndex, activeProductReference, "additionalInformation", e.target.value); }}
              />
            </Grid>
          </>}
        </>}
      </Grid>
    </div>;
  }
}
