const Languages = {
    2: { // Hansa
        "German": "de",
        "English" :"en",
        "Spanish": "es",
        "Italian": "it",
        "French": "fr",
        "Dutch": "nl",
        "Czech": "cs",
        "Slovak": "sk",
    },
    
    1: { // Oras
        "English" :"en",
        "Finnish" :"fi",
        "Swedish" :"sv",
        "Norwegian" :"no",
        "Danish" :"da",
        "Polish" :"pl",
        "Russian" :"ru",
        "Estonian" :"et",
        "Latvian" :"lv",
        "Lithuanian" :"lt",
        "Ukrainian" :"uk",
    }
};

export default Languages;