import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import LocalStorageService from './services/LocalStorageService.js';
import OffersArchive from './components/views/Offers/OffersArchive.js';
import OffersNew from './components/views/Offers/OffersNew.js';
import Login from './components/views/Login/Login.js';
import ExtraPages from './components/views/ExtraPages/ExtraPages.js';
import Settings from './components/views/Settings/Settings.js';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import strings from './lang/l10n';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Fira Sans Extra Condensed"',
      'sans-serif'
    ].join(','),
  },
});

class App extends Component {
  render() {
    const token = LocalStorageService.getToken();
    
    if (!token) {
      return <ThemeProvider theme={theme}>
        <Router>
          <Redirect to="/login" />
          <Route path="/login" render={() => <Login />} />
        </Router>
      </ThemeProvider>;
    }
    
    if (LocalStorageService.getUser().settings && LocalStorageService.getUser().settings.interfaceLanguage) {
      strings.setLanguage(LocalStorageService.getUser().settings.interfaceLanguage);
    }

    return <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/login" render={() => <Redirect to="/" />} />
          <Route path="/logout" render={LocalStorageService.logout} />
          <Route path="/new" render={() => <OffersNew />} />
          <Route path="/offers" render={() => <OffersArchive />} />
          <Route path="/extrapages" render={() => <ExtraPages/>} />
          <Route path="/settings" render={() => <Settings/>} />
          <Route render={() => <Redirect to="/offers" /> } />
        </Switch>
      </Router>
    </ThemeProvider>;
  }
}

export default App;
