import React, { Component } from 'react';
import './CoverPageTab.scss';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  Description,
  PermContactCalendar,
  Settings
} from '@material-ui/icons/';
import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core/';
import FilemanagerService from '../../../../services/FilemanagerService';
import strings from '../../../../lang/l10n';

// TODO: Create a theme and get rid of the inline styling... 

class CoverPageTab extends Component {
  constructor(props) {
    super(props);

    this.selectFile = this.selectFile.bind(this);
    this.displayFiles = this.displayFiles.bind(this);
  }
  
  selectFile() {
    FilemanagerService.select()
      .then((url) => {
        this.props.handleOfferTextfieldChange("coverPageFiles", [url]);
      });
  }

  displayFiles() {
    return this.props.offer.coverPageFiles.map((file) => {
      return <img key={""+file} alt="" width="120" height="120" src={file} />;
    });
  }

  render() {
    return <Grid container spacing={10}>
      <Grid item xs={6}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Description fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {strings.coverPageSettings.coverPageInfo}
            </Typography>
          </Grid>
        </Grid>

        <TextField
          required
          fullWidth
          margin="normal"
          label={strings.coverPageSettings.title}
          style={{ marginTop: 15 }}
          value={this.props.offer.coverPageTitle}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("coverPageTitle", e.target.value);
          }}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            fullWidth
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label={strings.coverPageSettings.date}
            value={this.props.offer.coverPageDate}
            onChange={(date) => {
              this.props.handleOfferTextfieldChange("coverPageDate", date);
            }}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label={strings.coverPageSettings.offerValidUntil}
            value={this.props.offer.offerValidUntil}
            onChange={(date) => {
              this.props.handleOfferTextfieldChange("offerValidUntil", date);
            }}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
          />
        </MuiPickersUtilsProvider>

        <TextField
          fullWidth
          margin="normal"
          label={strings.coverPageSettings.customerNumber}
          style={{ marginTop: 15 }}
          value={this.props.offer.customerNumber}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("customerNumber", e.target.value);
          }}
        />

        <TextField
          fullWidth
          label={strings.coverPageSettings.customerName}
          style={{ marginTop: 15 }}
          required
          value={this.props.offer.coverPageCustomerName}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("coverPageCustomerName", e.target.value);
          }}
        />
        <TextField
          fullWidth
          label={strings.coverPageSettings.addressLine1}
          style={{ marginTop: 15 }}
          value={this.props.offer.coverPageAddress1 || ""}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("coverPageAddress1", e.target.value);
          }}
        />
        <TextField
          fullWidth
          name="address2"
          label={strings.coverPageSettings.addressLine2}
          style={{ marginTop: 15 }}
          value={this.props.offer.coverPageAddress2 || ""}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("coverPageAddress2", e.target.value);
          }}
        />
        <TextField
          fullWidth
          label={strings.coverPageSettings.additionalText}
          multiline
          rows="4"
          variant="outlined"
          style={{ marginTop: 25 }}
          value={this.props.offer.coverPageExtraInfo}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("coverPageExtraInfo", e.target.value);
          }}
        />

        <Grid container direction="row" justify="flex-start" alignItems="center" style={{ marginTop: 15, marginBottom: 15 }}>
          <Grid item xs={4}>
          <Button variant="contained" component="label" onClick={this.selectFile}>
          {strings.coverPageSettings.selectFile}
          </Button>
          </Grid>
          <Grid item xs={8}>
            { (this.props.offer.coverPageFiles.length > 0 && this.displayFiles()) ||
              (<Typography variant="overline" display="block">
                {strings.coverPageSettings.noFilesSelected}
              </Typography>)
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <PermContactCalendar fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h6">
            {strings.coverPageSettings.salesContact}
            </Typography>
          </Grid>
        </Grid>

        <TextField
          fullWidth
          label={strings.name}
          style={{ marginTop: 15 }}
          value={this.props.offer.salesContactName || ""}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("salesContactName", e.target.value);
          }}
        />
        <TextField
          fullWidth
          label={strings.tel}
          style={{ marginTop: 15 }}
          value={this.props.offer.salesContactTel || ""}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("salesContactTel", e.target.value);
          }}
        />
        <TextField
          fullWidth
          label={strings.mobile}
          style={{ marginTop: 15 }}
          value={this.props.offer.salesContactMobile || ""}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("salesContactMobile", e.target.value);
          }}
        />
        <TextField
          fullWidth
          label={strings.email}
          style={{ marginTop: 15 }}
          value={this.props.offer.salesContactEmail || ""}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("salesContactEmail", e.target.value);
          }}
        />

        <Grid container spacing={1} alignItems="center" style={{ marginTop: 45, marginBottom: 10 }}>
          <Grid item>
            <Settings fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h6">
            {strings.coverPageSettings.settings}
            </Typography>
          </Grid>
        </Grid>

        <InputLabel id="langLabel">{strings.language}</InputLabel>
        <Select
          fullWidth
          labelId="langLabel"
          value={this.props.offer.settingsLanguage}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("settingsLanguage", e.target.value);
          }}
        >
          {Object.keys(this.props.offerLanguages).map((l)=> <MenuItem key={this.props.offerLanguages[l]} value={this.props.offerLanguages[l]}>{l}</MenuItem> )}
        </Select>

        <InputLabel id="marketLabel" style={{ marginTop: 20 }}>{strings.market}</InputLabel>
        <Select
          fullWidth
          labelId="marketLabel"
          value={this.props.offer.settingsMarket}
          onChange={(e) => {
            this.props.handleOfferTextfieldChange("settingsMarket", e.target.value);
          }}
        >
          {Object.values(this.props.offerMarkets).map((l) => <MenuItem key={l.code} value={l.code}>{l.name}</MenuItem> )}
        </Select>
      </Grid>
    </Grid>;
  }
}

export default CoverPageTab;